import React from "react";
import { Redirect } from "react-router-dom";
//import dashboardRoutes from "./views/dashboard/dashboardRoutes";
// import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
// import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
// import widgetsRoute from "./views/widgets/widgetsRoute";
// import chartsRoute from "./views/charts/chartsRoute";
// import dataTableRoute from "./views/dataTable/dataTableRoute";
// import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
// import pagesRoutes from "./views/pages/pagesRoutes";
// import iconsRoutes from "./views/icons/iconsRoutes";
// import responseAliasRoutes from "./views/app/responsealias/responseAliasRoutes";
// import usersAliasRoutes from "./views/app/users/usersRoutes";
// import inboxesRoutes from "./views/app/inboxes/inboxesRoutes";
// import inboxRoutes from "./views/app/inbox/inboxRoutes";
// import chatRoutes from "./views/app/chat/chatRoutes";
// import notificationsRoutes from "./views/app/notifications/notificationsRoutes";
// import conversationsRoutes from "./views/app/chat/conversationsRoutes";
// import calendarRoutes from "./views/app/calendar/calendarRoutes";
// import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
// import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
// import contactRoutes from "./views/app/contact/contactRoutes";
// import companiesRoutes from "./views/app/companies/companiesRoutes";
// import companyGroupsRoutes from "./views/app/companygroups/companyGroupsRoutes";
import transactionsRoutes from "./views/app/transactions/transactionsRoutes"
import accountRoutes from "./views/app/account/accountRoutes"
import estimationsRoutes from "./views/app/estimations/estimationsRoutes"
import ordersRoutes from "./views/app/orders/ordersRoutes"
 
const getBranch=localStorage.getItem('branch')
const getCustomer=localStorage.getItem('auth_user')
const getSessionType=localStorage.getItem('sessionType')
let redirectRoute = [];

if(getSessionType!==null){
  if(getSessionType==="0"){
    console.log('entro por cliente')
    if(getBranch!==null){
      console.log('ENTRO POR BRANCH')
      redirectRoute = [
        {
          path: "/",
          exact: true,
          component: () => <Redirect to="/app/transactions/table" />
        }
      ]
    }else{
      redirectRoute = [
        {
          path: "/",
          exact: true,
          component: () => <Redirect to="/app/session/branches" />
        }
      ]
      console.log('NO ENTRO POR BRANCH')
    }
  }else{
    console.log('entro por usuario')
    if(getCustomer!==null){
      console.log('ENTRO POR CUSTOMER')
      if(getBranch!==null){
        console.log('ENTRO POR BRANCH')
        redirectRoute = [
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/app/transactions/table" />
          }
        ]
      }else{
        redirectRoute = [
          {
            path: "/",
            exact: true,
            component: () => <Redirect to="/app/session/branches" />
          }
        ]
        console.log('NO ENTRO POR BRANCH')
      }
    }else{
      console.log('NO ENTRO POR CUSTOMER')
      redirectRoute = [
        {
          path: "/",
          exact: true,
          component: () => <Redirect to="/app/session/customers"/>
        }
      ]
    }
  }
}

const errorRoute = [
  {
    component: () => <Redirect to="/app/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      //...dashboardRoutes,
      // ...uiKitsRoutes,
      // ...formsRoutes,
      // ...widgetsRoute,
      // ...chartsRoute,
      // ...dataTableRoute,
      // ...extraKitsRoutes,
      // ...pagesRoutes,
      // ...iconsRoutes,
      // ...responseAliasRoutes,
      // ...usersAliasRoutes,
      // ...companiesRoutes,
      // ...companyGroupsRoutes,
      // ...inboxesRoutes,
      // ...inboxRoutes,
      // ...chatRoutes,
      // ...taskManagerRoutes,
      // ...calendarRoutes,
      // ...ecommerceRoutes,
      // ...contactRoutes,
      ...redirectRoute,
      // ...notificationsRoutes,
      // ...conversationsRoutes,
      ...transactionsRoutes,
      ...accountRoutes,
      ...estimationsRoutes,
      ...ordersRoutes,
      ...errorRoute
    ]
  }
];

export default routes;
