const layout2Settings = {
  leftSidebar: {
    theme: "sidebar-dark-purple",
  },
  header: {
    show: true
  },
  searchBox: {
    open: false
  },
  secondarySidebar: { show: false },
};

export default layout2Settings;
