import { lazy } from "react";

const Signup = lazy(() => import("./Signup"));

const Signin = lazy(() => import("./Signin"));

const ForgotPassword = lazy(() => import("./ForgotPassword"));

const Error404 = lazy(() => import("./Error"));

const Braches = lazy(() => import("./Branches"));

const SigninUsers = lazy(() => import("./SigninUsers"));

const Customers = lazy(() => import("./Customers"));

const sessionsRoutes = [
  {
    path: "/app/session/signup",
    component: Signup,
  },
  {
    path: "/app/session/signin",
    component: Signin,
  },
  {
    path: "/app/users/login",
    component: SigninUsers,
  },
  {
    path: "/app/session/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/app/session/404",
    component: Error404,
  },
  {
    path: "/app/session/branches",
    component: Braches,
  },
  {
    path: "/app/session/customers",
    component: Customers,
  },
];

export default sessionsRoutes;
