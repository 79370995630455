import { lazy } from "react";

const OrdersList = lazy(() => import("./ordersList"));
const OrdersCreate = lazy(() => import("./OrdersCreate"));
const OrderDetails = lazy(() => import("./OrderDetails"));

/* const ContactList = lazy(() => import("./ContactList"));

const ContactCard = lazy(() => import("./ContactCard"));

const ContactDetails = lazy(() => import("./ContactDetails")); */

const ordersRoutes = [
  {
    path: "/app/orders/list",
    component: OrdersList
  },
  {
    path: "/app/orders/create",
    component: OrdersCreate
  },
  {
    path: "/app/order/details/:id",
    component: OrderDetails
  }
  /* {
    path: "/app/contact/list",
    component: ContactList
  },
  {
    path: "/app/contact/card",
    component: ContactCard
  },
  {
    path: "/app/contact/details/:id",
    component: ContactDetails
  } */
];

export default ordersRoutes;
