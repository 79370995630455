import jwtAuthService from "../../services/jwtAuthService";
import { setUserData } from "./UserActions";
import history from "@history.js";
import swal from "sweetalert2";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_UNSUCCESS = "LOGIN_UNSUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword({
  user,
  password,
  company,
  sessionType,
}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    });

    jwtAuthService
      .loginWithEmailAndPassword(user, password, company, sessionType)
      .then((user) => {
        console.log("Exito", user);
        dispatch(setUserData(user));
        console.log("sessionType -> ", sessionType);
        if (sessionType === "0") {
          history.push("/app/session/branches");
        } else {
          history.push("/app/session/customers");
        }

        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        swal.fire({
          title: "Error!",
          text: "Usuario o password incorrectos.",
          icon: "error",
          timer: 3000,
        });

        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}
