import { lazy } from "react";

const AccountStatus = lazy(() => import("./AccountStatus"));

const Attachments = lazy(() => import("./Attachments"));

/* const ContactList = lazy(() => import("./ContactList"));

const ContactCard = lazy(() => import("./ContactCard"));

const ContactDetails = lazy(() => import("./ContactDetails")); */

const accountRoutes = [
  {
    path: "/app/account/attachments",
    component: Attachments,
  },
  {
    path: "/app/account/status",
    component: AccountStatus,
  },
  /* {
    path: "/app/contact/list",
    component: ContactList
  },
  {
    path: "/app/contact/card",
    component: ContactCard
  },
  {
    path: "/app/contact/details/:id",
    component: ContactDetails
  } */
];

export default accountRoutes;
