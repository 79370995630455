import { lazy } from "react";

const TransactionsTable = lazy(() => import("./transactionsTable"));

/* const ContactList = lazy(() => import("./ContactList"));

const ContactCard = lazy(() => import("./ContactCard"));

const ContactDetails = lazy(() => import("./ContactDetails")); */

const transactionsRoutes = [
  {
    path: "/app/transactions/table",
    component: TransactionsTable
  },
  /* {
    path: "/app/contact/list",
    component: ContactList
  },
  {
    path: "/app/contact/card",
    component: ContactCard
  },
  {
    path: "/app/contact/details/:id",
    component: ContactDetails
  } */
];

export default transactionsRoutes;
