/* API */
import axios from "axios";
/* import localStorageService from "app/services/localStorageService"; */
// const localStore = require('../utils/localStore');

export const qs = require("querystring");

export const client = axios.create({
  // baseURL: "http://demostracion.saasmexico.net/",
  // baseURL: "https://sucus.saasmexico.net"
  baseURL: window.saasmexico.url,
});

client.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("accessToken")}`;
client.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";

export const api_reload = () => {
  client.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("accessToken")}`;
  client.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};

const api_login_token = (user, password, company, sessionType) => {
  let urlSession =
    sessionType === "0"
      ? `modules/portal_cliente/api/v2/login`
      : `api/v2/login`;
  return client.post(
    urlSession,
    qs.stringify({ company: company, user: user, pass: password })
  );
};

const api_refresh = (user, password, company, sessionType) => {
  let urlSession =
    sessionType === "0"
      ? `modules/portal_cliente/api/v2/login`
      : `api/v2/login`;
  return client.post(
    urlSession,
    qs.stringify({ company: company, user: user, pass: password })
  );
};

/**
 * API LOGIN
 */
export const api_login = async (user, password, company, sessionType) => {
  const past_user = localStorage.getItem("user");
  const pass = localStorage.getItem("pass");
  const past_company = localStorage.getItem("company");
  const past_sessionType = localStorage.getItem("sessionType");

  if (!user) {
    user = past_user;
  }
  if (!password) {
    password = pass;
  }
  if (!company) {
    company = past_company;
  }
  if (!sessionType) {
    sessionType = past_sessionType;
  }

  if (localStorage.getItem("accessToken") == null) {
    return gettoken(user, password, company, sessionType);
  } else {
    return refresh(user, password, company, sessionType);
  }
};

/**
 * gettoken
 */
export const gettoken = (user, password, company, sessionType) => {
  return new Promise((resolve, reject) => {
    api_login_token(user, password, company, sessionType)
      .then((response) => {
        localStorage.setItem("user", user);
        localStorage.setItem("pass", password);
        localStorage.setItem("company", company);
        localStorage.setItem("sessionType", sessionType);
        //console.log('AUTH RES', response);
        if (response.data.token) {
          localStorage.setItem("accessToken", response.data.token);
          api_reload();
          resolve(response.data);
        } else {
          console.error("SHOULD NEVER HAPPEN", response);
          resolve(false);
        }
      })
      .catch((err) => {
        // NOT OK
        console.error("Error token");
        console.error(err.response);
        console.error("LOGIN ERROR", err);
        reject(false);
      });
  });
};

/**
 * REFRESH LOGIN
 */
export const refresh = (user, password, company, sessionType) => {
  return new Promise((resolve, reject) => {
    api_refresh(user, password, company, sessionType)
      .then((response) => {
        localStorage.setItem("user", user);
        localStorage.setItem("pass", password);
        localStorage.setItem("company", company);
        localStorage.setItem("sessionType", sessionType);
        if (response.data.token) {
          localStorage.setItem("accessToken", response.data.token);
          //console.log(localStore.get("accessToken"));
          api_reload();
          resolve(response.data);
        } else {
          console.error("RERESH SHOULD NEVER HAPPEN", response);
          localStorage.removeItem("accessToken");
          resolve(false);
        }
      })
      .catch((err) => {
        console.error("Error refresh");
        console.error(err.response);
        console.error("RERESH LOGIN ERROR", err);
        reject(false);
      });
  });
};

// /**
//  * GET
//  */
// export const get = url => {
//   return client.get(url);
// };

// /**
//  * POST
//  */
// export const post = (url, data) => {
//   return client.post(url, qs.stringify(data));
// };

// /**
//  * PUT
//  */
// export const put = (url, data) => {
//   return client.put(url, qs.stringify(data));
// };

export const getProducto = (producto) => {
  api_reload();
  return client.get(
    "api/v2/inventory/items?search=" +
      producto.code +
      "&sales_type_id=" +
      producto.sales_type_id +
      "&location_check=" +
      producto.location_check
  );
};

export const postOrder = (order) => {
  api_reload();
  return client.post(`api/v2/sales/order`, order); // qs.stringify(order)
};

export const postQuote = (quote) => {
  api_reload();
  return client.post(`api/v2/sales/quote`, quote); // qs.stringify(quote)
};

export const convertQuote = (url) => {
  api_reload();
  return client.put(url);
};

export const editQuote = (id, quote) => {
  api_reload();
  return client.put(`api/v2/sales/quotation/${id}`, quote); // qs.stringify(quote)
};

export const getStatusAccountPDF = (url) => {
  api_reload();
  return client.get(url);
};

export const getTransactions = (url) => {
  api_reload();
  return client.get(url);
};

export const getOrders = (url) => {
  api_reload();
  return client.get(url);
};

export const getEstimations = (url) => {
  api_reload();
  return client.get(url);
};

export const getAttachment = (url) => {
  api_reload();
  return client.get(url);
};

export const postAttachment = (url, params) => {
  api_reload();
  return client.post(url, qs.stringify(params));
};

export const getInformation = (url) => {
  api_reload();
  return client.get(url);
};

export const getPriceList = () => {
  api_reload();
  return client.get("api/v2/sales/salestypes?all=true&skip=0");
};
export const getPaymentTerms = () => {
  api_reload();
  return client.get("api/v2/configs/payment_terms?show_inactive");
};

export const getCustomers = (url) => {
  api_reload();
  return client.get(url);
};

export const getDocuments = (url) => {
  api_reload();
  return client.get(url);
};

export const getCustomerOverdueInvoices = (url) => {
  api_reload();
  return client.get(url);
};
