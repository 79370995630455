//import axios from "axios";
import { client } from "../../api/api";
import localStorageService from "./localStorageService";
import { api_login } from "api/api";

class JwtAuthService {
  loginWithEmailAndPassword = (user, password, company, sessionType) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api_login(user, password, company, sessionType));
      }, 1000);
    }).then((data) => {
      this.setSession(data.token);
      if (sessionType === "0") {
        this.setUserId(data.debtor_no);
        this.setUser(data.debtor);
        return data.debtor;
      } else {
        return data;
      }
    });
  };

  loginWithToken = (user, password, company, sessionType) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api_login(user, password, company, sessionType));
      }, 100);
    }).then((data) => {
      /* this.setUserId(data.debtor_no);
      this.setSession(data.token);
      this.setUser(data.debtor);
      return data.debtor; */
      this.setSession(data.token);
      if (sessionType === "0") {
        this.setUserId(data.debtor_no);
        this.setUser(data.debtor);
        return data.debtor;
      } else {
        return data;
      }
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("accessToken", token);
      client.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("accessToken");
      delete client.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  setUserId = (debtor_no) => {
    localStorageService.setItem("debtor_no", debtor_no);
  };
  removeUser = () => {
    /* localStorage.removeItem("auth_user");
    localStorage.removeItem("debtor_no");
    localStorage.removeItem("branch"); */
    localStorage.clear();
  };
}

export default new JwtAuthService();
