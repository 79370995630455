import { lazy } from "react";

const EstimationsList = lazy(() => import("./EstimationsList"));
const EstimationsCreate = lazy(() => import("./EstimationsCreate"));
const EstimationDetails = lazy(() => import("./EstimationDetails"));
const EstimationEdit = lazy(() => import("./EstimationEdit"));

/* const ContactList = lazy(() => import("./ContactList"));

const ContactCard = lazy(() => import("./ContactCard"));

const ContactDetails = lazy(() => import("./ContactDetails")); */

const estimationsRoutes = [
  {
    path: "/app/estimations/list",
    component: EstimationsList
  },
  {
    path: "/app/estimations/create",
    component: EstimationsCreate
  },
  {
    path: "/app/estimation/details/:id",
    component: EstimationDetails
  },
  {
    path: "/app/estimation/edit/:id",
    component: EstimationEdit
  }
  /* {
    path: "/app/contact/list",
    component: ContactList
  },
  {
    path: "/app/contact/card",
    component: ContactCard
  },
  {
    path: "/app/contact/details/:id",
    component: ContactDetails
  } */
];

export default estimationsRoutes;
