import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
//import firebaseAuthService from "../services/firebase/firebaseAuthService";

class Auth extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.props.setUserData(localStorageService.getItem("auth_user"));
    this.checkJwtAuth(props);
  }

  checkJwtAuth = (props) => {
    const location = props.children.props.children.props.history.location;
    const history = props.children.props.children.props.history;
    const pathname = location;

    jwtAuthService.loginWithToken()
    .then(user => {
      this.props.setUserData(user);
    })
    .catch(err => {
      console.log('ERROR y REDIRIGE =>', err);
      let redirectPage=props.children.props.children.props.history.location.pathname==="/app/session/signin"?"/app/session/signin":"/app/users/login"
      history.push({
        pathname: redirectPage,
        state: { redirectUrl: pathname }
      });
    });
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(mapStateToProps, { setUserData })(Auth);
