import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import GullLayout from "app/GullLayout/GullLayout";
import { flatMap } from "lodash";

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;

    this.state = {
      authenticated: true,
      routes
    };
  }

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, item => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      })
    });

    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props, state) {
    // const { location } = props;
    // const { pathname } = location;
    // const matched = state.routes.find(r => r.path === pathname);
    // TODO: AAD - NO VALIDAR EL ROL SOLO SI EL USUARIO ESTA ACTIVE
    const authenticated = localStorage.getItem('accessToken')?true:false;
    /*
    const authenticated =
      matched && matched.auth && matched.auth.length
        ? matched.auth.includes(user.role)
        : true;
    */
    return {
      authenticated
    };
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;
    /* console.log('window.location.host ->',window.location.host)
    const href=window.location
    console.log('href -> ',href)
    console.log('windowwindow.location.pathname ->',window.location.pathname)
    console.log('history->',history)
    console.log('history.location->',history.location)
    let redirectPage=props.history.location.pathname==="/app/session/signin"?"/app/session/signin":"/app/users/login"
    console.log('redirectPage ->', redirectPage)
    console.log('pathname ->',pathname) */
    history.push({
      pathname: "/app/session/signin",
      state: { redirectUrl: pathname }
    });
  }

  render() {
    let { route } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>
        <GullLayout route={route}></GullLayout>
      </Fragment>
    ) : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
